import React from "react"
import { Link } from "gatsby"

import Layout from "/src/components/layout"
import Gps from "/src/components/img/gps"
import ImgCon from "/src/components/img-con"
import SEO from "/src/components/seo"
import BtnWrap from "/src/components/btn-wrap"

import Gpso1 from "/src/components/img/gpso/1"

export default () => (
  <Layout>
    <SEO title="Přístroje a pomůcky pro měření směrů a úhlů" />
    <h1>Přístroje a pomůcky pro měření směrů a úhlů</h1>

    <p>Svisl&eacute; i vodorovn&iacute; &uacute;hly lze měřit množstv&iacute;m př&iacute;strojů a pomůcek pro měřen&iacute; směrů a &uacute;hlů. Nejčastěji se dnes v&nbsp;geod&eacute;zii měř&iacute; <strong>tot&aacute;ln&iacute; stanic&iacute;</strong> a <strong>teodolitem</strong>. Lze tak&eacute; ale využ&iacute;t &uacute;hloměry, pol&aacute;rn&iacute; koordin&aacute;tograf, transport&eacute;r a jin&eacute;.</p>
    <p>Mezi drobn&eacute; měřick&eacute; pomůcky patř&iacute; např&iacute;klad stativ, olovnice (určov&aacute;n&iacute; svislosti a prov&aacute;žen&iacute; bodů), libela (krabicov&aacute; a trubicov&aacute;) či dvojit&yacute; pentagon&aacute;ln&iacute; hranol (určit&eacute; &uacute;hly).</p>
    <hr /><h2>Rozdělen&iacute; teodolitů</h2>
    <p>Teodolity lze dělit jednak podle <strong>přesnosti</strong> na:</p>
    <ul>
    <li><strong>minutov&eacute; (stavebn&iacute;)</strong> &ndash; nejmen&scaron;&iacute; d&iacute;lek m&aacute; hodnotu jedn&eacute; minuty (ať už &scaron;edes&aacute;tinn&eacute;, či setinn&eacute;)</li>
    <li><strong>vteřinov&eacute;</strong> &ndash; nejmen&scaron;&iacute; d&iacute;lek o hodnotě vteřiny (&scaron;edes&aacute;tinn&aacute;, nebo setinn&aacute;)</li>
    <li><strong>triangulačn&iacute;</strong> &ndash; nejpřesněj&scaron;&iacute;, čten&iacute; až na desetiny vteřin</li>
    </ul>
    <p>D&aacute;le je lze dělit podle jejich <strong>konstrukce</strong> na:</p>
    <ul>
    <li><strong>optomechanick&eacute;</strong> (nutn&eacute; odeč&iacute;tat opticky ze stupnice)</li>
    <li><strong>elektronick&eacute;</strong> (digit&aacute;ln&iacute; z&aacute;znam čten&iacute;)</li>
    <li><strong>tot&aacute;ln&iacute; stanice</strong> se současn&yacute;m měřen&iacute;m &uacute;hlů a d&eacute;lek při c&iacute;len&iacute; na hranol (automaticky, digit&aacute;ln&iacute;)</li>
    </ul>
    <hr /><h2>Z&aacute;kladn&iacute; souč&aacute;sti teodolitu</h2>
    <p>Mezi tři z&aacute;kladn&iacute; souč&aacute;sti teodolitu (viz obrázek) patř&iacute; trojnožka (nepohybliv&aacute; č&aacute;st), alhid&aacute;da a dalekohled (pohybliv&eacute; č&aacute;sti).</p>
    <Gpso1 />
    <h3>Trojnožka</h3>
    <p>Trojnožka je spodn&iacute;, nepohybliv&aacute; č&aacute;st teodolitu. kter&aacute; připojuje př&iacute;stroj ke stativu. Najdeme zde tři <strong>stavěc&iacute; &scaron;rouby</strong> umožňuj&iacute;c&iacute; horizontaci př&iacute;stroje a <strong>svěrn&yacute; &scaron;roub</strong> trojnožky spojuj&iacute;c&iacute; tuto č&aacute;st se zbytkem př&iacute;stroje.</p>
    <h3>Alhid&aacute;da</h3>
    <p>Alhid&aacute;da je horn&iacute; č&aacute;st př&iacute;stroje děl&iacute;c&iacute; se na <strong>vodorovn&yacute;</strong> a <strong>svisl&yacute; kruh</strong>. Vodorovn&yacute; kruh n&aacute;m pom&aacute;h&aacute; při horizont&aacute;ln&iacute;m pohybu př&iacute;stroje a při měřen&iacute; je nepohybliv&yacute;. Svisl&yacute; naopak pohybuje s připevněn&yacute;m dalekohledem a při měřen&iacute; je s n&iacute;m nutno manipulovat pro přesn&eacute; zaměřen&iacute; na c&iacute;l.</p>
    <p>Nalezneme zde <strong>libelu</strong>, a to <strong>krabicovou</strong> (slouž&iacute;c&iacute; pro hrubou horizontaci) a <strong>trubicovou</strong> (slouž&iacute;c&iacute; pro jemnou horizontaci př&iacute;stroje). D&aacute;le dva p&aacute;ry <strong>ustanovek</strong> &ndash; <strong>horizont&aacute;ln&iacute;</strong> a <strong>vertik&aacute;ln&iacute;</strong>. Obě se pak děl&iacute; na <strong>ustanovku hrubou</strong> (fixuje př&iacute;stroj v&nbsp;dan&eacute; poloze) a <strong>jemnou</strong> (k přesn&eacute;mu zac&iacute;len&iacute; na dan&yacute; bod). Důležit&aacute; je i př&iacute;tomnost (repetičn&iacute;) <strong>svory</strong>, kterou lze zafixovat hodnotu čten&iacute;.</p>
    <h3>Dalekohled</h3>
    <p>Dalekohled se skl&aacute;d&aacute; z objektivu, kolim&aacute;toru, okul&aacute;ru a čtec&iacute;ho mikroskopu. V prvn&iacute; poloze dalekohledu je svisl&yacute; kruh př&iacute;stroje na lev&eacute; straně od okul&aacute;ru. Pomoc&iacute; ostř&iacute;c&iacute;ch zař&iacute;zen&iacute; tvoř&iacute;c&iacute; okul&aacute;r si můžeme zostřit samotn&yacute; obraz, nebo nitkov&yacute; kř&iacute;ž pro přesn&eacute; zac&iacute;len&iacute; na bod. <strong>Kolim&aacute;tor</strong> slouž&iacute; k hrub&eacute;mu zac&iacute;len&iacute; na bod, pro usnadněn&iacute; jeho hled&aacute;n&iacute; v ter&eacute;nu. <strong>Čtec&iacute; mikroskop</strong>, z&nbsp;kter&eacute;ho odeč&iacute;t&aacute;me hodnoty měřen&iacute;, se děl&iacute; na mř&iacute;žkov&yacute; a koincidenčn&iacute;.</p>

    <hr />
    <ImgCon><Gps /><div>
    <h2>Studijn&iacute; materi&aacute;ly Základy geodézie a GPS</h2>
    <p>T&eacute;ma <strong>Přístroje a pomůcky pro měření směrů a úhlů</strong> je souč&aacute;st&iacute; <Link to="/zaklady-geodezie-gps/">studijn&iacute;ch materi&aacute;lů Základy geodézie a GPS (GNSS)</Link>. Tento předmět byl vyučov&aacute;n na <a href="http://geogr.muni.cz">Geografick&eacute;m &uacute;stavu</a> <a href="http://muni.cz">Masarykovy univerzity</a>. Přejděte na rozcestn&iacute;k t&eacute;mat (1.&nbsp;tlač&iacute;tko), nebo si přečtěte předešlé (2. tlačítko) či n&aacute;sleduj&iacute;c&iacute;c&iacute; (3.&nbsp;tlač&iacute;tko) t&eacute;ma.</p>
    <BtnWrap>
    <Link to="/zaklady-geodezie-gps/"><button>Rozcestník geodézie a GPS</button></Link>
    <Link to="/zaklady-geodezie-gps/mereni-uhlu-smeru/"><button className="inv">&larr; Měření úhlů (směrů)</button></Link>
    <Link to="/zaklady-geodezie-gps/priprava-pristroje-chyby-mereni-uhlu/"><button className="inv">Příprava přístroje a chyby při geodetickém měření &rarr;</button></Link>
    </BtnWrap>
    </div></ImgCon><hr />

  </Layout>
)
